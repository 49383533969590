@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #2e3440;
  color: white;
  font-family: "Poppins", sans-serif;
}

a {
  color: white;
  cursor: pointer;
  text-decoration: none;
}
