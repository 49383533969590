.skills_container {
  margin: 10rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
  }

  img {
    width: 100%;
    user-select: none;
  }
}
