.hero_container {
  margin: 10rem 0;
  display: flex !important;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h1 {
    font-size: 3.5rem;
  }
}

.hero_icon_group {
  margin: 10px 0px;

  svg {
    font-size: 4.4rem;
  }
}

.hero_button_group {
  button {
    margin: 0px 10px;
  }
}
