.projects_container {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;

  div {
    margin: 15px;
    max-width: 344px;
    background-color: #2e3440;

    p {
      color: #d8dee9;
    }
  }
}
